import $i18n from '@alife/panda-i18n';
import React, { useState, useMemo } from 'react';
import { CnDialog, Field, CnTable } from '@alife/cn-ui';
import * as _ from 'lodash';
// import cnRequestWms from '@alife/cnc-cn-request-wms';
import cnRequestWms from 'src/api';
import Filter from './filter';
import api from './api';

const View = ({
  setTableVisible,
  outboundBillRecords,
  setOutboundBillRecords,
}) => {
  const url = api.queryRvNoticeList;
  const tableConfigs = {
    primaryKey: 'noticeCode', // 数据主键
    paging: true, // 是否分页
    showSelect: true, // 是否显示选择框
    defaultIsZebra: true, // 是否默认为斑马纹
    toolbar: {
      batchArea: [],
      toolArea: [],
      settings: ['size', 'fullscreen', 'columnSet', 'zebra'],
    },

    remote: {
      paramsTransform(val) {
        const params = {
          paging: {
            currentPage: val.currentPage,
            pageSize: val.pageSize,
          },

          ...val,
        };

        delete params.currentPage;
        delete params.pageSize;
        return {
          ...params,
        };
      },
      responseTransform: (res) => {
        // 过滤已选
        const tableItem = outboundBillRecords?.length
          ? res.items.filter((item) => {
              return !outboundBillRecords.includes(item.noticeCode);
            })
          : res.items;
        return {
          tableColumns: res.headers,
          tableData: tableItem,
          paging: { ...res.paging, total: res.paging.totalItems || 0 },
        };
      },
    },

    // 列表自定义渲染
    cellRenderMap: {},
  };

  /**
   * Filter 表单值
   */
  const [filterValues, setFilterValues] = useState({});
  // table 自带的分页数据
  const filterField = Field.useField({});
  // table 实例，可用于操作刷新等操作
  const tableInstance = CnTable.useRemote();

  function getContext(expand) {
    return {
      field: filterField,
      filterValues: filterField.getValues(),
      load: tableInstance.load,
      refresh: tableInstance.refresh,
      instance: tableInstance,
      ...expand,
      ...tableInstance.getInnerData(),
    };
  }

  function getBtnText(text, expand) {
    return `${_.isFunction(text) ? text(getContext(expand)) : text}`;
  }

  // 重组装了各区域按钮点击的回调
  const onButtonClick = (callback, isInOperateColumn) => {
    return (event, record, rowIndex) => {
      const expands = isInOperateColumn
        ? {
            record,
            rowIndex,
          }
        : {};
      _.isFunction(callback) && callback(getContext(expands));
    };
  };

  /**
   * 给相关按操作钮注入 Cnfilter 的 filterField 和 cntable 的 current
   */
  useMemo(() => {
    [
      // 批量操作区域
      'toolbar.batchArea',
      // 全局操作区域
      'toolbar.toolArea',
      // 操作列区域
      'operateColumn.buttons',
    ].forEach((buttonAreaPath) => {
      // 获取对应区域的按钮配置列表，可能是对象也可能是自定义函数
      const buttonConfigList = _.get(tableConfigs, buttonAreaPath, []);
      // 覆盖原有 onClick 能力
      buttonConfigList.forEach((operateItem, index) => {
        if (buttonAreaPath === 'operateColumn.buttons') {
          // 操作按钮
          if (_.isFunction(operateItem?.children)) {
            // children写法
            operateItem.children = onButtonClick(operateItem.children);
          } else if (operateItem?.$$typeof === Symbol.for('react.element')) {
            // 组件写法
            buttonConfigList[index] = {
              children: (record, i) =>
                React.cloneElement(operateItem, {
                  ...(operateItem?.props || {}),
                  getContext: () => getContext({ record, index: i }),
                  isInOperateColumn: true,
                  BtnWrapper: 'span',
                }),

              widthRule: {
                text: (record, i) =>
                  getBtnText(operateItem?.props?.btnText, { record, index: i }),
                // additionWidth: operateItem?.props?.additionWidth,
                additionWidth: (record, i) =>
                  getBtnText(operateItem?.props?.additionWidth, {
                    record,
                    index: i,
                  }),
              },
            };
          }
        } else if (
          operateItem &&
          operateItem.$$typeof === Symbol.for('react.element')
        ) {
          buttonConfigList[index] = React.cloneElement(operateItem, {
            ...(operateItem.props || {}),
            getContext,
            isInOperateColumn: buttonAreaPath === 'operateColumn.buttons',
          });
        } else if (_.isPlainObject(operateItem)) {
          _.set(
            tableConfigs,
            [buttonAreaPath, index, 'onClick'].join('.'),
            onButtonClick(
              operateItem.onClick,
              buttonAreaPath === 'operateColumn.buttons',
            ),
          );
        } else if (_.isFunction(operateItem)) {
          _.set(
            tableConfigs,
            [buttonAreaPath, index].join('.'),
            onButtonClick(
              operateItem,
              buttonAreaPath === 'operateColumn.buttons',
            ),
          );
        }
      });
    });

    const cellRenderMap = tableConfigs?.cellRenderMap ?? {};
    if (url || tableConfigs?.remote?.fetch) {
      tableConfigs.remote.columns = Array.isArray(tableConfigs.remote.columns)
        ? tableConfigs.remote.columns
        : [];
      Object.keys(cellRenderMap).forEach((mapKey) => {
        if (cellRenderMap[mapKey].$$typeof === Symbol.for('react.element')) {
          const filterNum = tableConfigs?.remote.columns.filter(
            (column) => (column?.dataIndex ?? column?.key) === mapKey,
          ).length;
          if (filterNum === 0) {
            tableConfigs?.remote.columns.push({
              dataIndex: mapKey,
              cell(value, index, record) {
                return React.cloneElement(cellRenderMap[mapKey], {
                  ...cellRenderMap[mapKey]?.props,
                  getContext: () =>
                    getContext({
                      dataIndex: mapKey,
                      value,
                      index,
                      record,
                    }),
                });
              },
            });
          }
        }
      });
    }
  }, [tableConfigs]);

  const filterProps = {
    field: filterField,
    onSearch: (values) => {
      setFilterValues(values);
    },
  };

  const setOutboundBillRecords2Parent = () => {
    const { selectInfo } = getContext();
    const tmpRecords = [
      ...(outboundBillRecords || []),
      ...(selectInfo?.current.length ? selectInfo.current[1] : []),
    ];

    setOutboundBillRecords(
      tmpRecords.reduce((result, record) => {
        if (!result.some((one) => one.id === record.id)) {
          result.push(record);
        }

        return result;
      }, []),
    );

    onClose();
  };

  const onClose = () => {
    setTableVisible(false);
  };

  return (
    <CnDialog
      title={$i18n.get({ id: '31255239910887424.CNTM', dm: '出库单' })}
      size={'large'}
      visible
      okProps={{
        children: $i18n.get({ id: 'Determine', dm: '确定' }),
      }}
      cancelProps={{
        children: $i18n.get({ id: 'Cancel', dm: '取消' }),
      }}
      onOk={() => {
        setOutboundBillRecords2Parent();
      }}
      onCancel={onClose}
      onClose={onClose}
    >
      <Filter filterProps={filterProps} />
      <CnTable
        {...tableConfigs}
        remote={{
          fetch: async (params) => {
            const tempParams = { ...params };

            if (tempParams.createTime) {
              if (tempParams.createTime[0]) {
                tempParams.createTimeStart = tempParams.createTime[0];
              }

              if (tempParams.createTime[1]) {
                tempParams.createTimeEnd = tempParams.createTime[1];
              }
            }

            if (tempParams.estimateOutTime) {
              if (tempParams.estimateOutTime[0]) {
                tempParams.estimateOutTimeStart = tempParams.estimateOutTime[0];
              }

              if (tempParams.estimateOutTime[1]) {
                tempParams.estimateOutTimeEnd = tempParams.estimateOutTime[1];
              }
            }

            const data = await cnRequestWms({
              url,
              method: 'post',
              data: tempParams,
            });

            return data;
          },
          manual: true, // 手动查询（为true不默认查询）
          ...tableConfigs.remote,
          params: filterValues,
          instance: tableInstance,
        }}
      />
    </CnDialog>
  );
};

export default View;
